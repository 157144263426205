import React from 'react'
import { useLocation } from 'react-router-dom'
import TopBarButton from './TopBarButton'

export default function TreeButton({ branch, handleClick, children }) {
  const { pathname } = useLocation()
  const currentBranch = pathname.toLowerCase().substring(1)
  const isActive = branch.toLowerCase() === currentBranch
  return (
    <>
      <TopBarButton
        onClick={() => handleClick(branch)}
        invertedColors={isActive}
      >
        {children}
      </TopBarButton>
    </>
  )
}
