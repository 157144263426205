export const address = "/.netlify/functions";
// export const address = 'http://localhost:9000';

export const formatPersonDetails = (dataFromApi) => {
  let formattedData = [];

  const titles = {
    name: "Όνομα",
    middleNames: "Δεύτερο όνομα",
    surname: "Επώνυμο",
    informalName: "Υποκοριστικό",
    nickname: "Παρατσούκλι",
    dob: "Ημερομηνία γέννησης",
    placeOfBirth: "Τόπος γέννησης",
    profession: "Επάγγελμα",
    dod: "Ημερομηνία θανάτου",
    timeOfDeath: "Ώρα θανάτου",
    placeOfDeath: "Τόπος θανάτου",
    ageAtDeath: "Ηλικία θανάτου",
    causeOfDeath: "Αίτιο θανάτου",
    christening: "Ημερομηνία βαφτήσεως",
    adoption: "Ημερομηνία υιοθεσίας",
    mumsNotes: "Σημειώσεις Χρυσούλας",
    notes: "Σημειώσεις",
    specialNotes: "Ειδικές σημειώσεις",
  };

  Object.keys(titles).forEach(function (key) {
    if (dataFromApi[key]) {
      formattedData.push({
        title: titles[key],
        detail: dataFromApi[key],
      });
    }
  });

  return formattedData;
};

export const getPersonDisplayName = (info) => {
  if (info.name) {
    let displayName = info.name;
    if (info.informalName) {
      displayName += " ";
      displayName += info.informalName;
    }
    if (info.surname) {
      displayName += " ";
      displayName += info.surname;
    }
    return displayName;
  } else if (info.nickname) {
    return info.nickname;
  }
  return "...";
};

export const formatPersonImages = (images) => {
  let formattedImages = [];
  images.forEach((image) =>
    formattedImages.push({
      title: image.title,
      location: require(`../static/${image.name}`),
      notes: image.notes,
    })
  );
  return formattedImages;
};

export const getCookiesAcceptance = () => {
  return JSON.parse(localStorage.getItem("cookiesAcceptance"));
};

export const getMapMarkers = () => {
  return [
    {
      lat: 35.274,
      lng: 24.2831,
      text: "Ασή Γωνιά",
      left: "-5.3rem",
      top: "-0.5rem",
    },
    {
      lat: 35.0191,
      lng: 24.832,
      text: "Κουσές",
      left: "-4rem",
      top: "-0.3rem",
    },
    {
      lat: 35.0515,
      lng: 24.873,
      text: "Μοίρες",
      left: "1rem",
      top: "-0.7rem",
    },
    {
      lat: 35.0096,
      lng: 24.8666,
      text: "Πόμπια",
      left: "0.5rem",
      top: "1rem",
    },
    {
      lat: 35.2851,
      lng: 24.3345,
      text: "Αργυρούπολη",
      left: "1rem",
      top: "-0.5rem",
    },
  ];
};

export const BRANCHES = {
  GYPARIS: 'gyparis',
  SKAPINAKIS: 'skapinakis',
  TAGARIS: 'tagaris',
  KSIRAKIS: 'ksirakis',
}

export const getPrettyBranchNamePlural = branchName => {
  switch (branchName) {
    case BRANCHES.SKAPINAKIS:
      return 'Σκαπινάκιδες'
    case BRANCHES.TAGARIS:
      return 'Ταγάριδες'
    case BRANCHES.KSIRAKIS:
      return 'Ξηράκιδες'
    case BRANCHES.GYPARIS:
    default:
      return 'Γυπαραίοι'
  }
}

export const getPrettyBranchName = branchName => {
  switch (branchName) {
    case BRANCHES.SKAPINAKIS:
      return 'Σκαπινάκης'
    case BRANCHES.TAGARIS:
      return 'Τάγαρης'
    case BRANCHES.KSIRAKIS:
      return 'Ξηράκης'
    case BRANCHES.GYPARIS:
    default:
      return 'Γύπαρης'
  }
}

export const getBranchGenerations = branchName => {
  switch (branchName) {
    case BRANCHES.SKAPINAKIS:
      return 6
    case BRANCHES.TAGARIS:
      return 5
    case BRANCHES.KSIRAKIS:
      return 5
    case BRANCHES.GYPARIS:
    default:
      return 7
  }
}

export const getFamilyTreeOptions = () => {
  let branches = []
  for (const key in BRANCHES) {
    branches.push(BRANCHES[key])
  }
  return branches.join('|')
}
