import styled from "styled-components";

export default styled.button`
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.normalText};

  margin: ${(props) => props.theme.backButtonMargin};
  padding: ${(props) => props.theme.backButtonPadding};

  border-radius: ${(props) => props.theme.backButtonBorderRadius};
  border-color: ${(props) => props.theme.backButtonBorderColor};

  color: ${(props) => props.theme.grey};
  background-color: ${(props) => props.theme.backButtonColor};

  &:hover {
    background-color: ${(props) => props.theme.backButtonHoverColor};
  }

  &:focus {
    outline: none;
    background-color: ${(props) => props.theme.backButtonHoverColor};
  }

  cursor: pointer;
`;
