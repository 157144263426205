import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";
import PageWrapper from "../PageWrapper";
import Button from "../Button";
import Marker from "./Marker";
import { getMapMarkers } from "../../Utilities";

export default function Map() {
  const [redirect, setRedirect] = useState(false);
  const handleClick = () => {
    setRedirect(true);
  };

  const center = { lat: 35.2401, lng: 24.8093 };
  const zoom = 9;
  const apiKey = "AIzaSyBiXbHOVFFPQLEfejibUqO2BnYKxm4HlDM";

  const markers = getMapMarkers();

  return (
    <PageWrapper>
      {redirect && <Redirect push to={"/"} />}
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
        >
          {markers.map((marker) => (
            <Marker
              lat={marker.lat}
              lng={marker.lng}
              key={marker.lat + marker.lng}
              text={marker.text}
              left={marker.left}
              top={marker.top}
            />
          ))}
        </GoogleMapReact>
        <Button onClick={handleClick}>Back to tree</Button>
      </Container>
    </PageWrapper>
  );
}

const Container = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  padding: 2rem;
  width: 60rem;
  height: 30rem;
`;
