import React from "react";
import styled from "styled-components";

export default function Marker(props) {
  return (
    <Dot
      lat={props.lat}
      lng={props.lng}
      text={props.text}
      left={props.left}
      top={props.top}
    />
  );
}

const Dot = styled.div`
	height: 0.7rem;
	width: 0.7rem;
	border-radius: 50%;
	background-color: ${(props) => props.theme.markerColor};
	&::before{	
		position: absolute;
		left: ${(props) => props.left};
		top: ${(props) => props.top};

		color: ${(props) => props.theme.grey};
		font-size: ${(props) => props.theme.normalText};

		content: '${(props) => props.text}';
		background-color: ${(props) => props.theme.white};
		padding: 0.3rem;
		border-radius: 8px;
		border: ${(props) => props.theme.boxBorder};
	}
`;
