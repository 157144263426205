import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import { connect } from "react-redux";
import Marker from "../Marker";
import { getPersonDisplayName } from "../../../Utilities";

function Person(props) {
  const [redirect, setRedirect] = useState(false);

  const handleClick = () => {
    setRedirect(true);
  };

  const getBackgroundColor = () => {
    const gender = props.basicInfo.gender;
    if (gender === "female") {
      return props.theme.pink;
    } else if (gender === "male") {
      return props.theme.babyBlue;
    }
  };

  const location = useLocation();
  return (
    <>
      {redirect && (
        <Redirect
          push
          to={location.pathname + "/person/" + props.basicInfo.id}
        />
      )}
      {props.basicInfo && (
        <PersonDiv
          {...props}
          background={getBackgroundColor()}
          onClick={handleClick}
        >
          <MarkerContainer>
            <Marker
              adopted={props.adopted}
              direction={props.direction}
              specialNotes={props.basicInfo.specialNotes}
            />
          </MarkerContainer>
          <DisplayNameDiv>
            {getPersonDisplayName(props.basicInfo)}
          </DisplayNameDiv>
          {props.basicInfo.dob && (
            <DateOfBirthDiv>{props.basicInfo.dob}</DateOfBirthDiv>
          )}
          {props.basicInfo.dod && (
            <DateOfDeathDiv>{props.basicInfo.dod}</DateOfDeathDiv>
          )}
        </PersonDiv>
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  return {
    basicInfo: state.allPersons.filter(
      (person) => String(person.id) === props.id
    )[0],
  };
}

export default connect(mapStateToProps)(withTheme(Person));

const PersonDiv = styled.div`
  z-index: 0;
  border: ${(props) => props.theme.boxBorder};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.padding};

  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  font-weight: ${(props) => (props.spouse === "true" ? "normal" : "bold")};
  background: ${(props) => props.background};

  display: grid;
  grid-gap: ${(props) => props.theme.personGridColumnGap};

  cursor: pointer;
`;

const DisplayNameDiv = styled.div`
  text-align: center;
  grid-row: 2;
  grid-column: 1 / span 2;
`;

const DateOfBirthDiv = styled.div`
  font-size: ${(props) => props.theme.smallText};
  font-weight: normal;

  justify-self: start;
  grid-row: 3;
  grid-column: 1;
`;

const DateOfDeathDiv = styled.div`
  font-size: ${(props) => props.theme.smallText};
  font-weight: normal;

  justify-self: end;
  grid-row: 3;
  grid-column: 2;
`;

const MarkerContainer = styled.div`
  position: absolute;
  z-index: 1;
  margin: -0.3em 0 0 -0.3em;
`;
