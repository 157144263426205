import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { getAllPersons } from "../../Api/Data";
import {
  getPrettyBranchNamePlural,
  getBranchGenerations,
  BRANCHES,
} from '../../Utilities'
import ZoomBar from "./TopBars/ZoomBar";
import ButtonsBar from "./TopBars/ButtonsBar";
import GenBar from "./TopBars/GenBar";
import PageWrapper from "../PageWrapper";
import GyparisTree from "./Trees/GyparisTree";
import SkapinakisTree from "./Trees/SkapinakisTree";
import TagarisTree from "./Trees/TagarisTree";
import KsirakisTree from "./Trees/KsirakisTree";

function FamilyTree(props) {
  const authenticated = props.authenticated;

  useEffect(() => {
    if (authenticated) {
      getAllPersons(props.level);
    }
  }, [authenticated, props.level]);

  useEffect(() => {
    document.title = getPrettyBranchNamePlural(branch)
  })

  const branch = props.match.url.toLowerCase().substring(1);
  const numberGenerations = getBranchGenerations(branch)
  const columnWidth = props.theme.columnWidth;
  const topBarsWidth = columnWidth * numberGenerations + "rem";

  return (
    <PageWrapper>
      {props.allPersons.length > 0 && (
        <>
          <StickyContainer>
            <ZoomBar width={topBarsWidth} />
            <ButtonsBar width={topBarsWidth} />
            <GenBar number={numberGenerations} width={topBarsWidth} />
          </StickyContainer>
          <Container zoom={props.zoom}>
            {branch === BRANCHES.GYPARIS && <GyparisTree {...props} />}
            {branch === BRANCHES.SKAPINAKIS && <SkapinakisTree {...props} />}
            {branch === BRANCHES.TAGARIS && <TagarisTree {...props} />}
            {branch === BRANCHES.KSIRAKIS && <KsirakisTree {...props} />}
          </Container>
        </>
      )}
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    level: state.level,
    zoom: state.zoom,
    allPersons: state.allPersons,
  };
}

export default connect(mapStateToProps)(withTheme(FamilyTree));

const Container = styled.div`
  zoom: ${(props) => props.zoom};
`;

const StickyContainer = styled.div`
  z-index: 100;
  position: sticky;
  top: 0;
`;
