import React from "react";
import Children from "../Children";
import Child from "../PersonCard/Child";
import Spouse from "../PersonCard/Spouse";
import Plus from "../Plus";
import Row from "../Row";

export default function KsirakisTree(props) {
  return (
    <Children border="false">
      <Child id="112" />
      <Plus />
      <Spouse id="113" />
      <Children>
        <Child id="114" />
        <Child id="115" />
        <Child id="21" />
        <Plus />
        <Spouse id="11" />
        <Children>
          <Row order="1 / span 2">
            <Child id="36" adopted="formally" direction="to" />
          </Row>
          <Row order="1">
            <Plus />
            <Spouse id="176" />
          </Row>
          <Row order="2">
            <Plus />
            <Spouse id="48" />
            <Children>
              <Child id="63" />
              <Plus />
              <Spouse id="75" />
              <Children>
                <Child id="89" />
                <Child id="90" />
              </Children>
            </Children>
          </Row>
          <Row order="3">
            <Child id="37" />
          </Row>
          <Row order="4">
            <Child id="38" />
          </Row>
        </Children>
      </Children>
    </Children>
  );
}
