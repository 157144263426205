import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Marker from "../Marker";

function Legend(props) {
  return (
    <Container>
      <Grid>
        <MarkerContainer>
          <Marker adopted="formally" />
        </MarkerContainer>
        <Label>Formally adopted</Label>
        <MarkerContainer>
          <Marker adopted="informally" />
        </MarkerContainer>
        <Label>Informally adopted</Label>
        <MarkerContainer>
          <Marker direction="from" />
        </MarkerContainer>
        <Label>Biological family</Label>
        <MarkerContainer>
          <Marker direction="to" />
        </MarkerContainer>
        <Label>Adoptive family</Label>
        {props.authLevel === "superAdvanced" && (
          <>
            <MarkerContainer>
              <Marker specialNotes="true" />
            </MarkerContainer>
            <Label>Special Notes</Label>
          </>
        )}
      </Grid>
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    authLevel: state.level,
  };
}

export default connect(mapStateToProps)(Legend);

const Container = styled.div`
  display: inline-block;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(11, auto);
  grid-template-rows: 1em;
  grid-column-gap: 0.3em;
`;

const Label = styled.div`
  padding-right: 1em;
`;

const MarkerContainer = styled.div`
  padding: 0.3em 0 0.3em 0;
`;
