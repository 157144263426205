import ActionType from "./ActionTypes";
import store from "./Store";

export function makeActionDispatcher(type, ...argumentNames) {
  return function (...args) {
    let action = { type };
    args.forEach((arg, index) => {
      action[argumentNames[index]] = args[index];
    });
    store.dispatch(action);
  };
}

export const setAuthenticated = makeActionDispatcher(
    ActionType.SET_AUTHENTICATED,
    "authenticated"
  ),
  setAuthLevel = makeActionDispatcher(ActionType.SET_AUTH_LEVEL, "level"),
  setZoom = makeActionDispatcher(ActionType.SET_ZOOM, "zoom"),
  setAllPersons = makeActionDispatcher(
    ActionType.SET_ALL_PERSONS,
    "allPersons"
  ),
  setPersonDetails = makeActionDispatcher(
    ActionType.SET_PERSON_DETAILS,
    "personDetails"
  ),
  setUnclassifiedData = makeActionDispatcher(
    ActionType.SET_UNCLASSIFIED_DATA,
    "unclassifiedData"
  ),
  setMaxVisibleGeneration = makeActionDispatcher(
    ActionType.SET_MAX_VISIBLE_GENERATION,
    "maxVisibleGeneration"
  ),
  setError = makeActionDispatcher(ActionType.SET_ERROR, "error"),
  clearStore = makeActionDispatcher(ActionType.CLEAR_STORE);
