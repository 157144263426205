import React from "react";
import styled from "styled-components";

export default function Plus(props) {
  return (
    <PlusDiv gridColumn="2" {...props}>
      +
    </PlusDiv>
  );
}

const PlusDiv = styled.div`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  justify-self: center;
`;
