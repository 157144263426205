import React from "react";
import { connect } from "react-redux";
import { setMaxVisibleGeneration } from "../../../Redux/Actions";
import styled from "styled-components";

function GenBar(props) {
  const generation = "Γενεά ";

  const handleClick = (generation) => {
    setMaxVisibleGeneration(generation);
  };

  const getGenerationBars = () => {
    let children = [];
    for (let i = 1; i <= props.number; i++) {
      children.push(
        <Generation key={i} onClick={() => handleClick(i)}>
          {generation + i}
        </Generation>
      );
    }
    return children;
  };

  return (
    <Container zoom={props.zoom} width={props.width}>
      {getGenerationBars()}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    zoom: state.zoom,
  };
}

export default connect(mapStateToProps)(GenBar);

const Container = styled.div`
  width: ${(props) => props.width};
  color: #ffffff;
  background-color: ${(props) => props.theme.grey};
  zoom: ${(props) => props.zoom};
`;

const Generation = styled.div`
  display: inline-block;
  text-align: center;
  font-weight: bold;

  width: ${(props) => props.theme.genWidth};
  padding-right: ${(props) => props.theme.genPadding};
  padding-left: ${(props) => props.theme.genPadding};
  margin: ${(props) => props.theme.genMargin};

  cursor: pointer;
`;
