import axios from "axios";
import {
  setAuthenticated,
  setAuthLevel,
  setError,
  clearStore,
} from "../Redux/Actions";
import { address } from "../Utilities";

const setLocalStorage = (value, level) => {
  const now = new Date();
  const expiryTimeMs = 14400000; // Expire every 4 hours
  localStorage.setItem(
    "authenticated",
    JSON.stringify({
      value: value,
      level: level,
      expiry: now.getTime() + expiryTimeMs,
    })
  );
  localStorage.removeItem("nextAttempt");
};

const setEarliestNextAttempt = () => {
  const now = new Date();
  localStorage.setItem("nextAttempt", JSON.stringify(now.getTime() + 3000));
};

const isAuthenticationAllowed = () => {
  const now = new Date();
  const data = localStorage.getItem("nextAttempt");
  if (data) {
    const nextAttempt = JSON.parse(data);
    if (now.getTime() < nextAttempt) {
      return false;
    }
  }
  return true;
};

export const authenticate = (password) => {
  if (!isAuthenticationAllowed()) {
    setAuthenticated(false);
    setEarliestNextAttempt();
    return;
  }

  const url = `${address}/login`;
  return axios
    .post(url, { password: password })
    .then((response) => {
      if (response.data.success) {
        setAuthenticated(true);
        setAuthLevel(response.data.level);
        setLocalStorage(true, response.data.level);
        setError(false);
      }
    })
    .catch((error) => {
      const response = error.response;
      if (
        response.status === 403 &&
        response.data.message.toLowerCase().includes("incorrect password")
      ) {
        setAuthenticated(false);
        setEarliestNextAttempt();
        setLocalStorage(false, response.data.level);
        setError(false);
      } else {
        setError(true);
      }
    });
};

export const logout = () => {
  localStorage.removeItem("authenticated");
  clearStore();
};
