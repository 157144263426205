export default {
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_AUTH_LEVEL: "SET_AUTH_LEVEL",
  SET_ZOOM: "SET_ZOOM",
  SET_ALL_PERSONS: "SET_ALL_PERSONS",
  SET_PERSON_DETAILS: "SET_PERSON_DETAILS",
  SET_UNCLASSIFIED_DATA: "SET_UNCLASSIFIED_DATA",
  SET_MAX_VISIBLE_GENERATION: "SET_MAX_VISIBLE_GENERATION",
  SET_ERROR: "SET_ERROR",
  CLEAR_STORE: "CLEAR_STORE",
};
