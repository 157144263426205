import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { setAuthenticated, setAuthLevel } from "../Redux/Actions";
import theme from "../Theme";
import Login from "./Login";
import NotFound from "./NotFound";
import FamilyTree from "./FamilyTree/FamilyTree";
import PersonDetails from "./PersonDetails";
import Unclassified from "./Unclassified";
import Cookies from "./Cookies";
import Map from "./Map/Map";
import { getFamilyTreeOptions } from "../Utilities";

export default function App(props) {
  const trackingId = "UA-163430377-1";
  ReactGA.initialize(trackingId);
  const history = createBrowserHistory();
  // Initialize google analytics page view tracking
  history.listen((location) => {
    ReactGA.set({ page: location.pathname + location.search }); // Update the user's current page
    ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page
  });
  useEffect(() => {
    const storedData = localStorage.getItem("authenticated");
    if (storedData) {
      const data = JSON.parse(storedData);
      const now = new Date();
      if (now.getTime() > data.expiry) {
        localStorage.removeItem("authenticated");
      } else {
        setAuthenticated(data.value);
        setAuthLevel(data.level);
      }
    }
  }, []);

  const familyTrees = getFamilyTreeOptions()

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageContainer>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/">
              <Redirect push to="/gyparis" />
            </Route>
            <Route exact path={`/(${familyTrees})`} component={FamilyTree} />
            <Route
              path={`/(${familyTrees})/person/:id`}
              component={PersonDetails}
            />
            <Route exact path="/map" component={Map} />
            <Route exact path="/unclassified" component={Unclassified} />
            <Route exact path="/cookies" component={Cookies} />
          </Switch>
        </Router>
      </PageContainer>
    </ThemeProvider>
  );
}

const GlobalStyle = createGlobalStyle`
	body {
		margin: 0px;
		overscroll-behavior-x: none;
	}
`;

const PageContainer = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.normalText};
  color: ${(props) => props.theme.grey};
`;
