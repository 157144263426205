import React, { useState, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import PageWrapper from "./PageWrapper";
import { getPersonDetails } from "../Api/Data";
import {
  formatPersonDetails,
  getPersonDisplayName,
  formatPersonImages,
} from "../Utilities";
import Button from "./Button";

function PersonDetails(props) {
  const [goBack, setGoBack] = useState(false);
  const handleClick = () => {
    setGoBack(true);
  };

  const [notFound, setNotFound] = useState(false);

  const id = props.match.params.id;
  useEffect(() => {
    getPersonDetails(id, props.level).then((notFound) => {
      setNotFound(notFound);
    });
  }, [id, props.level]);

  useEffect(() => {
    if (props.personDetails) {
      document.title = getPersonDisplayName(props.personDetails);
    }
  });

  const location = useLocation();
  const path = location.pathname.toLowerCase().substring(1);
  const branch = path.substring(0, path.indexOf("/"));

  return (
    <PageWrapper>
      {notFound && (
        <Redirect
          push
          to={{
            pathname: "/404",
            state: { redirect: branch },
          }}
        />
      )}
      {goBack && <Redirect push to={`/${branch}`} />}
      {props.personDetails && (
        <>
          {formatPersonDetails(props.personDetails).map((detail) => (
            <Detail key={detail.title}>
              <Title>{detail.title}</Title>
              {Array.isArray(detail.detail) ? (
                <div>
                  {detail.detail.map((x) =>
                    x.startsWith("https:") ? (
                      <Link
                        key={x}
                        href={x}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {x}
                      </Link>
                    ) : (
                      <MultiLineText key={x}>{x}</MultiLineText>
                    )
                  )}
                </div>
              ) : (
                <OneLineText>{detail.detail}</OneLineText>
              )}
            </Detail>
          ))}
          {props.personDetails.images &&
            formatPersonImages(props.personDetails.images).map((image) => (
              <ImageContainer key={image.location}>
                <ImageTitle>{image.title}</ImageTitle>
                <Image
                  src={image.location}
                  alt={image.title}
                  key={image.location}
                  height="420"
                />
                {image.notes &&
                  image.notes.length > 0 &&
                  image.notes.map((note) => (
                    <ImageNote key={note}>{note}</ImageNote>
                  ))}
              </ImageContainer>
            ))}
        </>
      )}
      <ButtonContainer>
        <Button onClick={handleClick}>Back to tree</Button>
      </ButtonContainer>
    </PageWrapper>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    level: state.level,
    personDetails: state.personDetails.filter(
      (person) => String(person.id) === ownProps.match.params.id
    )[0],
  };
}

export default connect(mapStateToProps)(PersonDetails);

const Detail = styled.div`
  padding: 0.8rem 2rem;
  margin: 1rem 20%;
  border: ${(props) => props.theme.detailBorder};
  border-radius: ${(props) => props.theme.borderRadius};
`;

const Title = styled.div`
  font-weight: bold;
  padding-bottom: 0.4rem;
  display: inline-block;
  width: 12rem;
`;

const OneLineText = styled.div`
  padding-bottom: 0.3rem;
  display: inline-block;
`;

const MultiLineText = styled.div`
  padding-bottom: 0.3rem;
`;

const Link = styled.a`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  padding: 0.8rem 2rem;
  margin: 1rem 20%;
  border: ${(props) => props.theme.detailBorder};
  border-radius: ${(props) => props.theme.borderRadius};
`;

const ImageTitle = styled.div`
  font-weight: bold;
  padding-bottom: 0.4rem;
`;

const Image = styled.img`
  padding-bottom: 0.3rem;
`;

const ImageNote = styled.div`
  padding-bottom: 0.3rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
