import React from "react";
import styled from "styled-components";

export default function TopBarButton(props) {
  return (
    <StyledButton onClick={props.onClick} invertedColors={props.invertedColors}>
      {props.children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  padding: ${(props) => props.theme.buttonPadding};
  margin: ${(props) => props.theme.buttonMargin};
  font-size: ${(props) => props.theme.buttonFontSize};

  border-radius: ${(props) => props.theme.zoomBorderRadius};
  background: ${(props) =>
    props.invertedColors ? props.theme.pink : props.theme.babyBlue};
  color: ${(props) => props.theme.white};

  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    background: ${(props) =>
      props.invertedColors ? props.theme.blue : props.theme.pink};
    color: ${(props) => props.theme.white};
  }

  &:focus {
    outline: none;
  }
`;
