import React from "react";
import styled from "styled-components";

export default function Cookies() {
  return (
    <Container>
      <Title>Cookie Policy for kourmou.li</Title>
      <Text>
        This is the Cookie Policy for kourmou.li, accessible from kourmou.li
      </Text>
      <Header>What Are Cookies</Header>
      <Text>
        As is common practice with almost all professional websites this site
        uses cookies, which are tiny files that are downloaded to your computer,
        to improve your experience. This page describes what information they
        gather, how we use it and why we sometimes need to store these cookies.
        We will also share how you can prevent these cookies from being stored
        however this may downgrade or 'break' certain elements of the sites
        functionality.
      </Text>
      <Text>
        For more general information on cookies, please read
        <Link
          href="https://www.cookieconsent.com/what-are-cookies/"
          target="_blank"
          rel="noopener noreferrer"
        >
          What Are Cookies
        </Link>
        .
      </Text>
      <Header>How We Use Cookies</Header>
      <Text>
        We use cookies for a variety of reasons detailed below. Unfortunately in
        most cases there are no industry standard options for disabling cookies
        without completely disabling the functionality and features they add to
        this site. It is recommended that you leave on all cookies if you are
        not sure whether you need them or not in case they are used to provide a
        service that you use.
      </Text>
      <Header>Disabling Cookies</Header>
      <Text>
        You can prevent the setting of cookies by adjusting the settings on your
        browser (see your browser Help for how to do this). Be aware that
        disabling cookies will affect the functionality of this and many other
        websites that you visit. Disabling cookies will usually result in also
        disabling certain functionality and features of the this site. Therefore
        it is recommended that you do not disable cookies.
      </Text>
      <Header>The Cookies We Set</Header>
      <Header>Login related cookies</Header>
      <Text>
        We use cookies when you are logged in so that we can remember this fact.
        This prevents you from having to log in every single time you visit a
        new page. These cookies are typically removed or cleared when you log
        out to ensure that you can only access restricted features and areas
        when logged in.
      </Text>
      <Header>Third Party Cookies</Header>
      <Text>
        In some special cases we also use cookies provided by trusted third
        parties. The following section details which third party cookies you
        might encounter through this site.
      </Text>
      <Text>
        This site uses Google Analytics which is one of the most widespread and
        trusted analytics solution on the web for helping us to understand how
        you use the site and ways that we can improve your experience. These
        cookies may track things such as how long you spend on the site and the
        pages that you visit so we can continue to produce engaging content.
      </Text>
      <Text>
        For more information on Google Analytics cookies, see the official
        Google Analytics page.
      </Text>
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem 5rem;
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.largeText};
  padding: 1rem 0;
  font-weight: bold;
`;

const Header = styled.div`
  font-size: ${(props) => props.theme.normalText};
  padding: 0.3rem 0;
  font-weight: bold;
`;

const Text = styled.div`
  font-size: ${(props) => props.theme.normalText};
  padding: 0.3rem 0;
`;

const Link = styled.a`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 0.3rem;
  font-weight: bold;

  &:visited {
    color: ${(props) => props.theme.grey};
  }
`;
