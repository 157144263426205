import axios from "axios";
import { setUnclassifiedData, setError } from "../Redux/Actions";
import { address } from "../Utilities";

export const getUnclassifiedData = (level) => {
  if (!level) {
    return;
  }
  const url = `${address}/unclassified?level=${level}`;
  return axios
    .get(url)
    .then((response) => setUnclassifiedData(response.data))
    .catch(() => setError(true));
};
