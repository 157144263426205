import React from "react";
import { connect } from "react-redux";
import { setZoom } from "../../../Redux/Actions";
import styled from "styled-components";
import TopBarButton from "./TopBarButton";
import Legend from "./Legend";

function ZoomBar(props) {
  const increment = 0.1;

  const increaseZoom = () => {
    const increasedZoom = props.zoom + increment;
    if (increasedZoom < 1.5) {
      setZoom(increasedZoom);
    }
  };

  const decreaseZoom = () => {
    const decreasedZoom = props.zoom - increment;
    if (decreasedZoom > 0.4) {
      setZoom(decreasedZoom);
    }
  };

  const resetZoom = () => {
    if (props.zoom !== 1) {
      setZoom(1);
    }
  };

  return (
    <>
      <EmptyContainer width={props.width}>
        <TopBarButton onClick={increaseZoom}>+</TopBarButton>
        <TopBarButton onClick={decreaseZoom}>-</TopBarButton>
        <TopBarButton onClick={resetZoom}>Reset</TopBarButton>
        <Legend />
      </EmptyContainer>
    </>
  );
}

const EmptyContainer = styled.div`
  height: ${(props) => props.theme.navBarContainerHeight};
  background-color: ${(props) => props.theme.white};
  width: ${(props) => props.width};
`;

function mapStateToProps(state) {
  return {
    zoom: state.zoom,
  };
}

export default connect(mapStateToProps)(ZoomBar);
