import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

function Children(props) {
  const currentGeneration = props.generation ?? 1;
  const nextGeneration = currentGeneration + 1;

  const childrenWithGeneration = React.Children.map(props.children, (child) =>
    React.cloneElement(child, { generation: nextGeneration })
  );

  const hideGeneration =
    props.maxVisibleGeneration &&
    props.maxVisibleGeneration <= currentGeneration;

  return (
    <>
      {hideGeneration ? (
        <></>
      ) : (
        <Container gridColumn="4" gridRow={props.gridRow}>
          <ChildrenDiv {...props}>{childrenWithGeneration}</ChildrenDiv>
        </Container>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    maxVisibleGeneration: state.maxVisibleGeneration,
  };
}

export default connect(mapStateToProps)(Children);

const Container = styled.div`
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
`;

const ChildrenDiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.theme.gridColumns};
  grid-row-gap: ${(props) => props.theme.gridRowGap};
  grid-column-gap: ${(props) => props.theme.gridColumnGap};
  justify-items: stretch;
  align-items: center;

  border-left: ${(props) =>
    props.border === "false" ? "none" : props.theme.border};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.padding};
`;
