import React from 'react'
import Children from '../Children'
import Child from '../PersonCard/Child'
import Spouse from '../PersonCard/Spouse'
import Plus from '../Plus'

const TagarisTree = () => (
  <Children border="false">
    <Child id="48" />
    <Plus />
    <Spouse id="36" />
    <Children>
      <Child id="63" />
      <Plus />
      <Spouse id="75" />
      <Children>
        <Child id="89" />
        <Child id="90" />
      </Children>
    </Children>
  </Children>
)

export default TagarisTree
