import React from "react";
import Children from "../Children";
import Child from "../PersonCard/Child";
import Spouse from "../PersonCard/Spouse";
import Plus from "../Plus";
import Row from "../Row";

export default function GyparisTree(props) {
  return (
    <Children border="false">
      <Child id="1" />
      <Plus />
      <Spouse id="2" />
      <Children>
        <Row order="1">
          <Child id="3" />
          <Plus />
          <Spouse id="4" />
          <Children>
            <Row order="1">
              <Child id="5" />
            </Row>
            <Row order="2 / span 2">
              <Child id="6" />
            </Row>
            <Row order="2">
              <Plus />
              <Spouse id="12" />
              <Children>
                <Child id="23" />
                <Child id="24" />
              </Children>
            </Row>
            <Row order="3">
              <Plus />
              <Spouse id="13" />
              <Children>
                <Child id="25" />
                <Child id="26" adopted="informally" direction="to" />
              </Children>
            </Row>
            <Row order="4">
              <Child id="7" />
              <Plus />
              <Spouse id="14" />
              <Children>
                <Child id="27" adopted="informally" direction="to" />
                <Child
                  id="28"
                  adopted={props.level === "superAdvanced" ? "formally" : ""}
                  direction={props.level === "superAdvanced" ? "to" : ""}
                />
                <Plus />
                <Spouse id="40" />
                <Children>
                  <Child id="49" />
                  <Plus />
                  <Spouse id="64" />
                  <Children>
                    <Child id="76" />
                    <Child id="77" />
                    <Plus />
                    <Spouse id="91" />
                    <Children>
                      <Child id="94" />
                    </Children>
                  </Children>
                  <Child id="50" />
                  <Plus />
                  <Spouse id="65" />
                  <Children>
                    <Child id="78" />
                    <Child id="79" />
                  </Children>
                </Children>
              </Children>
            </Row>
            <Row order="5 / span 2">
              <Child id="8" />
            </Row>
            <Row order="5">
              <Plus />
              <Spouse id="15" />
              <Children>
                <Child
                  id="29"
                  adopted={props.level === "superAdvanced" ? "formally" : ""}
                  direction={props.level === "superAdvanced" ? "to" : ""}
                />
                <Plus />
                <Spouse id="41" />
                <Children>
                  <Child id="51" />
                  <Plus />
                  <Spouse id="66" />
                  <Children>
                    <Child id="80" />
                    <Child id="81" />
                    <Plus />
                    <Spouse id="92" />
                  </Children>
                  <Child id="52" />
                  <Plus />
                  <Spouse id="67" />
                  <Children>
                    <Child id="82" />
                    <Plus />
                    <Spouse id="93" />
                    <Children>
                      <Child id="95" />
                      <Child id="96" />
                    </Children>
                  </Children>
                  <Child id="53" />
                  <Plus />
                  <Spouse id="68" />
                  <Children>
                    <Child id="83" />
                    <Child id="84" />
                  </Children>
                  <Child id="54" />
                </Children>
              </Children>
            </Row>
            {props.level === "superAdvanced" ? (
              <Row order="6">
                <Plus />
                <Spouse id="16" />
              </Row>
            ) : (
              <></>
            )}
            <Row order="7 / span 2">
              <Child id="9" />
            </Row>
            <Row order="7">
              <Plus />
              <Spouse id="17" />
              <Children>
                <Child id="30" />
                <Child id="31" />
                <Child id="32" />
                <Plus />
                <Spouse id="42" />
                <Children>
                  <Child id="55" />
                  <Plus />
                  <Spouse id="69" />
                  <Children>
                    <Child id="85" />
                  </Children>
                </Children>
              </Children>
            </Row>
            <Row order="8">
              <Plus />
              <Spouse id="18" />
            </Row>
            <Row order="9 / span 2">
              <Child id="10" />
            </Row>
            <Row order="9">
              <Plus />
              <Spouse id="19" />
              <Children>
                <Child id="27" adopted="informally" direction="from" />
                <Child id="26" adopted="informally" direction="from" />
                <Plus />
                <Spouse id="43" />
              </Children>
            </Row>
            <Row order="10">
              <Plus />
              <Spouse id="20" />
              <Children>
                <Row order="1 / span 2">
                  <Child id="33" />
                </Row>
                <Row order="1">
                  <Plus />
                  <Spouse id="44" />
                  {props.level === "superAdvanced" ? (
                    <Children>
                      <Child id="56" />
                    </Children>
                  ) : (
                    <></>
                  )}
                </Row>
                <Row order="2">
                  <Plus />
                  <Spouse id="45" />
                  <Children>
                    <Child id="57" />
                    <Plus />
                    <Spouse id="70" />
                    <Children>
                      <Child id="86" />
                      <Plus />
                      <Spouse id="116" />
                      <Children>
                        <Child id="117" />
                      </Children>
                      <Child id="87" />
                      <Plus />
                      <Spouse id="118" />
                    </Children>
                    <Child id="58" />
                    <Plus />
                    <Spouse id="71" />
                    <Children>
                      <Child id="88" />
                      <Plus />
                      <Spouse id="154" />
                      <Children>
                        <Child id="155" />
                      </Children>
                    </Children>
                  </Children>
                </Row>
                <Child id="34" />
                <Plus />
                <Spouse id="46" />
                <Children>
                  <Child id="59" />
                  <Plus />
                  <Spouse id="72" />
                  <Child id="60" />
                  <Plus />
                  <Spouse id="73" />
                </Children>
                <Child id="35" />
                <Plus />
                <Spouse id="47" />
                <Children>
                  <Child id="61" />
                  <Plus />
                  <Spouse id="74" />
                  <Children>
                    <Child id="157" />
                  </Children>
                  <Child id="62" />
                  <Plus />
                  <Spouse id="119" />
                  <Children>
                    <Child id="121" />
                    <Child id="120" />
                  </Children>
                </Children>
                <Child id="36" adopted="formally" direction="from" />
              </Children>
            </Row>
            <Row order="11 / span 2">
              <Child id="11" />
            </Row>
            <Row order="11">
              <Plus />
              <Spouse id="21" />
              <Children>
                <Child id="37" />
                <Child id="38" />
                <Row order="3 / span 2">
                  <Child id="36" adopted="formally" direction="to" />
                </Row>
                <Row order="3">
                  <Plus />
                  <Spouse id="176" />
                </Row>
                <Row order="4">
                  <Plus />
                  <Spouse id="48" />
                  <Children>
                    <Child id="63" />
                    <Plus />
                    <Spouse id="75" />
                    <Children>
                      <Child id="89" />
                      <Child id="90" />
                    </Children>
                  </Children>
                </Row>
              </Children>
            </Row>
            <Row order="12">
              <Plus />
              <Spouse id="22" />
              <Children>
                <Child id="39" />
              </Children>
            </Row>
          </Children>
        </Row>
        <Row order="2">
          <Child id="122" />
          <Plus />
          <Spouse id="123" />
          <Children>
            <Child id="156" />
            <Child id="143" />
            <Plus />
            <Spouse id="144" />
            <Children>
              <Child id="145" />
              <Plus />
              <Spouse id="147" />
              <Children>
                <Child id="148" />
                <Plus />
                <Spouse id="150" />
                <Child id="149" />
                <Plus />
                <Spouse id="151" />
              </Children>
              <Child id="146" />
              <Plus />
              <Spouse id="152" />
              <Children>
                <Child id="153" />
              </Children>
            </Children>
            <Child id="158" />
            <Plus />
            <Spouse id="159" />
            <Child id="124" />
            <Plus />
            <Spouse id="131" />
            <Children>
              <Child id="128" />
              <Plus />
              <Spouse id="129" />
              <Children>
                <Child id="130" />
                <Plus />
                <Spouse id="167" />
                <Children>
                  <Child id="168" />
                  <Child id="169" />
                </Children>
              </Children>
            </Children>
            <Child id="125" />
            <Child id="126" />
            <Child id="127" />
            <Child id="132" />
            <Plus />
            <Spouse id="133" />
            <Children>
              <Child id="134" />
              <Plus />
              <Spouse id="135" />
              <Child id="162" />
              <Child id="163" />
              <Child id="164" />
              <Child id="165" />
              <Child id="166" />
            </Children>
            <Child id="160" />
            <Child id="136" />
            <Plus />
            <Spouse id="137" />
            <Children>
              <Child id="138" />
            </Children>
            <Child id="139" />
            <Plus />
            <Spouse id="140" />
            <Children>
              <Child id="141" />
              <Child id="142" />
            </Children>
            <Child id="161" />
          </Children>
        </Row>
        <Row order="3">
          <Child id="170" />
          <Plus />
          <Spouse id="172" />
        </Row>
        <Row order="4">
          <Child id="171" />
        </Row>
        <Row order="5 / span 2">
          <Child id="173" />
        </Row>
        <Row order="5">
          <Plus />
          <Spouse id="174" />
        </Row>
        <Row order="6">
          <Plus />
          <Spouse id="175" />
        </Row>
      </Children>
    </Children>
  );
}
