import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { authenticate } from "../Api/Auth";
import Button from "./Button";
import CookiesBanner from "./CookiesBanner";
import { getCookiesAcceptance } from "../Utilities";

function Login(props) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const url = props.location.state ? props.location.state.origin : "/";

  const handleSubmit = () => {
    if (password) {
      authenticate(password);
      setEmptyPassword(false);
    } else {
      setEmptyPassword(true);
    }
  };

  return (
    <Container>
      Password:
      <Field>
        <Input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <VisibilityButton onClick={toggleShowPassword}>
          {showPassword ? "Hide" : "Show"}
        </VisibilityButton>
      </Field>
      <Button onClick={handleSubmit}>Submit</Button>
      {!getCookiesAcceptance() && <CookiesBanner />}
      {emptyPassword && <ErrorDiv>Please enter a password</ErrorDiv>}
      {props.authenticated === false && !emptyPassword && (
        <ErrorDiv>Incorrect password</ErrorDiv>
      )}
      {props.error && <ErrorDiv>Oops, something went wrong</ErrorDiv>}
      {props.authenticated === true && <Redirect push to={url} />}
    </Container>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: state.authenticated,
    error: state.error,
  };
}

export default connect(mapStateToProps)(Login);

const Container = styled.div`
  margin: 10%;
  text-align: center;
  font-size: ${(props) => props.theme.normalText};
`;

const Field = styled.div`
  margin: 1em;
  padding: 0.7em;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  display: inline;
`;

const Input = styled.input`
  border: none;
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.normalText};
  &:focus {
    outline: none;
  }
`;

const VisibilityButton = styled.button`
  border: none;
  width: 4em;
  text-align: right;
  color: ${(props) => props.theme.grey};
  &:focus {
    outline: none;
  }
`;

const ErrorDiv = styled.div`
  color: ${(props) => props.theme.red};
`;
