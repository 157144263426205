import axios from "axios";
import { setAllPersons, setPersonDetails, setError } from "../Redux/Actions";
import { address } from "../Utilities";

export const getAllPersons = (level) => {
  if (!level) {
    return;
  }
  const url = `${address}/persons?level=${level}`;
  return axios
    .get(url)
    .then((response) => setAllPersons(response.data))
    .catch(() => setError(true));
};

export const getPersonDetails = (id, level) => {
  if (!level) {
    return new Promise(() => false);
  }
  const url = `${address}/personDetails/${id}?level=${level}`;
  return axios
    .get(url)
    .then((response) => {
      setPersonDetails(response.data);
      return response.data === "";
    })
    .catch(() => setError(true));
};
