import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../../Api/Auth";
import { getPrettyBranchName, BRANCHES } from '../../../Utilities'
import { setMaxVisibleGeneration } from "../../../Redux/Actions";
import styled from "styled-components";
import TopBarButton from "./TopBarButton";
import TreeButton from "./TreeButton";

function ButtonsBar(props) {
  const [redirect, setRedirect] = useState();
  const handleClick = (branch) => {
    setMaxVisibleGeneration(null);
    if (branch) {
      setRedirect("/" + branch);
    }
  };

  const getBranchButtons = () => {
    let buttons = []
    for (const key in BRANCHES) {
      const branchName = BRANCHES[key]
      const prettyName = getPrettyBranchName(branchName)
      buttons.push(
        <TreeButton handleClick={() => handleClick(branchName)} branch={key}>
          {prettyName}
        </TreeButton>
      )
    }
    return buttons
  }

  return (
    <>
      {redirect && <Redirect push to={redirect} />}
      <EmptyContainer width={props.width}>
        <TopBarButton onClick={logout}>Log out</TopBarButton>
        {getBranchButtons()}
        <TreeButton handleClick={handleClick} branch="map">
          Χάρτης
        </TreeButton>
        {props.level === 'superAdvanced' && (
          <TreeButton handleClick={handleClick} branch="unclassified">
            Unclassified
          </TreeButton>
        )}
      </EmptyContainer>
    </>
  );
}

function mapStateToProps(state) {
  return {
    level: state.level,
  };
}

export default connect(mapStateToProps)(ButtonsBar);

const EmptyContainer = styled.div`
  height: ${(props) => props.theme.navBarContainerHeight};
  background-color: ${(props) => props.theme.white};
  width: ${(props) => props.width};
`;
