import ActionType from "./ActionTypes";

const initialState = {
  authenticated: null,
  level: null,
  zoom: 1,
  allPersons: [],
  personDetails: [],
  unclassifiedData: [],
  maxVisibleGeneration: null,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ActionType.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case ActionType.SET_AUTH_LEVEL:
      return {
        ...state,
        level: action.level,
      };
    case ActionType.SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom,
      };
    case ActionType.SET_ALL_PERSONS:
      return {
        ...state,
        allPersons: action.allPersons,
      };
    case ActionType.SET_PERSON_DETAILS:
      if (
        state.personDetails.filter(
          (person) => person.id === action.personDetails.id
        ).length > 0
      ) {
        return {
          ...state,
        };
      } else {
        const personDetails = state.personDetails.concat([
          action.personDetails,
        ]);
        return {
          ...state,
          personDetails: personDetails,
        };
      }
    case ActionType.SET_UNCLASSIFIED_DATA:
      return {
        ...state,
        unclassifiedData: action.unclassifiedData,
      };
    case ActionType.SET_MAX_VISIBLE_GENERATION:
      return {
        ...state,
        maxVisibleGeneration:
          state.maxVisibleGeneration === action.maxVisibleGeneration
            ? null
            : action.maxVisibleGeneration,
      };
    case ActionType.SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case ActionType.CLEAR_STORE:
      return initialState;
    default:
      return state;
  }
}
