import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { getUnclassifiedData } from "../Api/Unclassified";
import PageWrapper from "./PageWrapper";
import Button from "./Button";

function Unclassified(props) {
  useEffect(() => {
    getUnclassifiedData(props.level);
  }, [props.level]);

  useEffect(() => {
    document.title = "Unclassified";
  });

  return (
    <PageWrapper>
      {props.level === "normal" && <Redirect push to="/" />}
      <>
        <Title>Unclassified information</Title>
        {props.level === "superAdvanced" &&
          props.data.length > 0 &&
          props.data.map((info) => (
            <React.Fragment key={info.notes[0]}>
              <Info key={info.notes[0]}>
                {info.notes.map((note) => (
                  <Note key={note}>
                    {note.startsWith("http") ? (
                      <Link
                        key={note}
                        href={note}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {note}
                      </Link>
                    ) : (
                      note
                    )}
                  </Note>
                ))}
              </Info>
              {info.images &&
                info.images.length > 0 &&
                info.images.map((image) => (
                  <Image
                    src={require(`../../static/${image}`)}
                    alt={image}
                    key={image}
                    width={"50%"}
                  />
                ))}
            </React.Fragment>
          ))}
        {props.data.length === 0 && <Info>No data to show.</Info>}
        <ButtonContainer>
          <Button onClick={props.history.goBack}>Back</Button>
        </ButtonContainer>
      </>
    </PageWrapper>
  );
}

function mapStateToProps(state) {
  return {
    level: state.level,
    data: state.unclassifiedData,
  };
}

export default connect(mapStateToProps)(Unclassified);

const Title = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.largeText};
  margin: 1rem 0 1rem 0;
`;

const Info = styled.div`
  padding: 0.8rem 2rem;
  margin: 1rem 20%;
  border: ${(props) => props.theme.detailBorder};
  border-radius: ${(props) => props.theme.borderRadius};
`;

const Note = styled.div`
  padding-bottom: 0.5rem;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Image = styled.img`
  padding: 0.8rem 2rem;
  margin: 1rem 20%;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
