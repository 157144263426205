import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import CookiesBanner from "./CookiesBanner";
import { getCookiesAcceptance } from "../Utilities";

function PageWrapper(props) {
  const location = useLocation();
  return (
    <>
      {!getCookiesAcceptance() && <CookiesBanner />}
      {!props.authenticated && (
        <Redirect
          to={{
            pathname: "/login",
            state: { origin: location.pathname },
          }}
        />
      )}
      {props.error && <Container>Oops, something went wrong!</Container>}
      {!props.error && props.children}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    authenticated: state.authenticated,
    error: state.error,
  };
}

export default connect(mapStateToProps)(PageWrapper);

const Container = styled.div`
  font-size: ${(props) => props.theme.largeText};
  font-weight: bold;
  text-align: center;
  padding: 5%;
`;
