import React from "react";

export default function Row(props) {
  const childrenWithGridRow = React.Children.map(props.children, (child) =>
    React.cloneElement(child, {
      gridRow: props.order,
      generation: props.generation,
    })
  );
  return <>{childrenWithGridRow}</>;
}
