const rem = "rem ";

const padding = 0.6;
const paddingRem = padding + rem;

const gridRowGap = 1.8;
const gridRowGapRem = gridRowGap + rem;

const gridColumnGap = 0.9;
const gridColumnGapRem = gridColumnGap + rem;

const genPadding = 0.7;
const genPaddingRem = genPadding + rem;
const genMargin = 0.6;
const genMarginRem = genMargin + rem;

const standardGridColumn = 9;
const gridColumn1 = standardGridColumn;
const gridColumn2 = 0.7;
const gridColumn3 = standardGridColumn;
const gridColumn4 = standardGridColumn;

const gridColumn1Rem = gridColumn1 + rem;
const gridColumn2Rem = gridColumn2 + rem;
const gridColumn3Rem = gridColumn3 + rem;
const gridColumn4Rem = gridColumn4 + rem;
const gridColumns =
  gridColumn1Rem +
  "" +
  gridColumn2Rem +
  "" +
  gridColumn3Rem +
  "" +
  gridColumn4Rem;

const genWidth = gridColumn1 + gridColumn2 + gridColumn3 + gridColumnGap;
const genWidthRem = genWidth + rem;

const columnWidth = genWidth + 2 * (genPadding + genMargin);

export default {
  // Font
  font: "system-ui",
  normalText: "15px",
  smallText: "11px",
  largeText: "18px",

  // Colours
  grey: "#6E6E80",
  babyBlue: "#B1DFE5",
  pink: "#F5B5C1",
  white: "#FFFFFF",
  black: "#000000",
  red: "#de4812",
  greenBlue: "#44a6c7",
  green: "#009e7c",
  markerColor: "#de4812",

  // Border
  boxBorder: "1px solid #6E6E80",
  border: "1.4px solid #6E6E80",
  borderRadius: "8px",
  detailBorder: "1px solid #d8d8d8",
  cookiesButtonBorder: "1px solid #FFFFFF",

  // Multiple marriages links
  plusBorderNormal: "1px solid #6E6E80",
  plusBorderWarning: "2px solid #de4812",

  // Padding
  padding: paddingRem,

  // Grid
  gridColumns: gridColumns,
  gridRowGap: gridRowGapRem,
  gridColumnGap: gridColumnGapRem,

  // Topbar dimensions
  columnWidth: columnWidth,

  // Genbar dimensions
  genWidth: genWidthRem,
  genPadding: genPaddingRem,
  genMargin: genMarginRem,

  // Navbar dimensions
  navBarContainerHeight: "5vh",

  // Zoom buttons
  // measured in vh because we don't want them to be affected by zoom
  zoomBorderRadius: "2px",
  buttonPadding: "0.3vh 3vh",
  buttonMargin: "0.5vh 2vh",
  buttonFontSize: "2.5vh",

  // Person card
  personGridColumnGap: "0.2rem",

  // Back button
  backButtonMargin: "20px 0px 12px 0px",
  backButtonPadding: "10px 16px",
  backButtonBorderRadius: "6px",
  backButtonBorderColor: "#d8d8d8 #d1d1d1 #bababa",
  backButtonColor: "#ffffff",
  backButtonHoverColor: "#f0f0f5",

  // Marker border
  markerBorder: "1px solid #6E6E80",
};
