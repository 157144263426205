import React from "react";
import Children from "../Children";
import Child from "../PersonCard/Child";
import Spouse from "../PersonCard/Spouse";
import Plus from "../Plus";
import Row from "../Row";

export default function SkapinakisTree(props) {
  return (
    <Children border="false">
      <Child id="97" />
      <Plus />
      <Spouse id="98" />
      <Children>
        <Child id="99" />
        <Child id="100" />
        <Plus />
        <Spouse id="102" />
        <Children>
          <Child id="104" />
          <Plus />
          <Spouse id="177" />
          <Children>
            <Child id="178" />
            <Plus />
            <Spouse id="180" />
            <Children>
              <Child id="181" />
              <Child id="182" />
            </Children>
            <Child id="179" />
            <Plus />
            <Spouse id="183" />
            <Children>
              <Child id="184" />
              <Plus />
              <Spouse id="185" />
              <Children>
                <Child id="186" />
                <Child id="187" />
              </Children>
            </Children>
          </Children>
          <Child id="105" />
          <Child id="106" />
          <Plus />
          <Spouse id="107" />
          <Children>
            <Child id="108" />
            <Plus />
            <Spouse id="109" />
            <Children>
              <Child id="110" />
              <Child id="111" />
            </Children>
          </Children>
        </Children>
        <Child id="101" />
        <Plus />
        <Spouse id="103" />
        <Child id="20" />
        <Plus />
        <Spouse id="10" />
        <Children>
          <Row order="1 / span 2">
            <Child id="33" />
          </Row>
          <Row order="1">
            <Plus />
            <Spouse id="44" />
            {props.level === "superAdvanced" ? (
              <Children>
                <Child id="56" />
              </Children>
            ) : (
              <></>
            )}
          </Row>
          <Row order="2">
            <Plus />
            <Spouse id="45" />
            <Children>
              <Child id="57" />
              <Plus />
              <Spouse id="70" />
              <Children>
                <Child id="86" />
                <Plus />
                <Spouse id="116" />
                <Children>
                  <Child id="117" />
                </Children>
                <Child id="87" />
                <Plus />
                <Spouse id="118" />
              </Children>
              <Child id="58" />
              <Plus />
              <Spouse id="71" />
              <Children>
                <Child id="88" />
                <Plus />
                <Spouse id="154" />
                <Children>
                  <Child id="155" />
                </Children>
              </Children>
            </Children>
          </Row>
          <Row order="3">
            <Child id="34" />
            <Plus />
            <Spouse id="46" />
            <Children>
              <Child id="59" />
              <Plus />
              <Spouse id="72" />
              <Child id="60" />
              <Plus />
              <Spouse id="73" />
            </Children>
          </Row>
          <Row order="4">
            <Child id="35" />
            <Plus />
            <Spouse id="47" />
            <Children>
              <Child id="61" />
              <Plus />
              <Spouse id="74" />
              <Children>
                <Child id="157" />
              </Children>
              <Child id="62" />
              <Plus />
              <Spouse id="119" />
              <Children>
                <Child id="121" />
                <Child id="120" />
              </Children>
            </Children>
          </Row>
          <Row order="5 / span 2">
            <Child id="36" adopted="formally" direction="from" />
          </Row>
          <Row order="5">
            <Plus />
            <Spouse id="176" />
          </Row>
          <Row order="6">
            <Plus />
            <Spouse id="48" />
            <Children>
              <Child id="63" />
              <Plus />
              <Spouse id="75" />
              <Children>
                <Child id="89" />
                <Child id="90" />
              </Children>
            </Children>
          </Row>
        </Children>
      </Children>
    </Children>
  );
}
