import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

export default function CookiesBanner() {
  const [hide, setHidden] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const acceptCookies = () => {
    localStorage.setItem("cookiesAcceptance", JSON.stringify({ value: true }));
    setHidden(true);
  };
  return (
    <>
      {moreInfo && <Redirect push to={"/cookies"} />}
      <Banner display={hide ? "none" : ""}>
        <Text>
          We use cookies to ensure that we give you the best experience on our
          website.
        </Text>
        <Link
          onClick={() => setMoreInfo(true)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click here
        </Link>
        <Text>for more information.</Text>
        <Button onClick={acceptCookies}>Okay</Button>
      </Banner>
    </>
  );
}

const Banner = styled.div`
  display: ${(props) => props.display};
  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.grey};
  text-align: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 5rem;
  z-index: 999;
`;

const Text = styled.div`
  display: inline-block;
`;

const Link = styled.div`
  text-align: center;
  display: inline-block;
  padding: 0 0.2rem;
  text-decoration: underline;
  cursor: pointer;
`;

const Button = styled.div`
  position: fixed;
  right: 1rem;
  padding: 0.3rem;
  bottom: 0.7rem;
  cursor: pointer;
  border: ${(props) => props.theme.cookiesButtonBorder};
  border-radius: ${(props) => props.theme.borderRadius};

  &:hover {
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.grey};
  }
`;
