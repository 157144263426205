import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import PageWrapper from "./PageWrapper";
import Button from "./Button";

export default function NotFound(props) {
  const [redirect, setRedirect] = useState(false);
  const handleClick = () => {
    setRedirect(true);
  };
  return (
    <PageWrapper>
      {redirect && <Redirect push to={"/"} />}
      <Container>
        <Text>Person was not found.</Text>
        <Button onClick={handleClick}>Back to tree</Button>
      </Container>
    </PageWrapper>
  );
}

const Container = styled.div`
  text-align: center;
  padding: 2rem;
`;

const Text = styled.div`
  font-size: ${(props) => props.theme.largeText};
`;
