import React from "react";
import styled, { withTheme } from "styled-components";

function Marker(props) {
  const getColor = () => {
    if (props.adopted === "formally") {
      return props.theme.green;
    } else if (props.adopted === "informally") {
      return props.theme.greenBlue;
    } else if (props.specialNotes) {
      return props.theme.red;
    }
  };

  const getShape = () => {
    if (props.direction === "to") {
      return "square";
    }
  };

  const getBorder = () => {
    if (!props.adopted && props.direction) {
      return props.theme.markerBorder;
    }
  };

  return <Sign color={getColor()} shape={getShape()} border={getBorder()} />;
}

export default withTheme(Marker);

const Sign = styled.div`
  height: 0.6em;
  width: 0.6em;

  border: ${(props) => props.border};
  background-color: ${(props) => props.color};
  border-radius: ${(props) => (props.shape === "square" ? "0%" : "50%")};
`;
